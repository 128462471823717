
// Methods to handle workflow for study lock, read status, etc.
//
import uuid from 'uuid'
import Vue from 'vue'
import permissions from './permissions'
import webServices from './webServices'
import store from '../store'

const TARGET_REPORT_SIDEPANEL = 'report_sidepanel'
const TARGET_REPORT_WINDOW = 'report_window'
const TARGET_VIEWER = 'viewer'

// Returns target key for a study lock.
//
function getTargetKey(target, windowUid) {
    let suffix = (windowUid == '') ? '' : `_${windowUid}`
    return target + suffix
}

// Finds study currently holding lock for target.
//
function getLockedStudyUid(target, windowUid='') {
    var lockedStudyUid = ''
    let studyUids = Object.keys(store.state.openStudies)
    const targetKey = getTargetKey(target, windowUid)
    for (var k = 0; k < studyUids.length; k++) {
        let studyUid = studyUids[k]
        if (store.state.openStudies[studyUid].includes(targetKey)) {
            lockedStudyUid = studyUid
            break
        }
    }
    return lockedStudyUid
}

// Handles locking study if needed.
//
async function openStudy(studyUid, target, windowUid='') {
    return new Promise((resolve, reject) => {
        let closeStudyUid = getLockedStudyUid(target, windowUid)
        var worklistEntry = store.getters.worklistEntryForStudy(studyUid)
        if (worklistEntry != null) {
            var canLockStudy = permissions.hasPermission(worklistEntry.group, permissions.CAN_LOCK_STUDY)
            if (canLockStudy) {
                // Get lock for study (always checking server in case another user has opened before worklist refreshes)
                //
                webServices.updateStudyLock(worklistEntry, 'lock')
                .then(response => {
                    if (response.result == 'OK') {
                        worklistEntry.lock = response.studyLock
                        worklistEntry.lufn = store.getters.user
                        let mgmtReportEntries = store.getters.mgmtReportEntriesForStudy(studyUid)
                        for (var i=0; i < mgmtReportEntries.length; i++) {
                            mgmtReportEntries[i]._l = response.studyLock
                        }
                        if (store.state.openStudies[studyUid] == undefined) {
                            store.state.openStudies[studyUid] = []
                        }
                        const targetKey = getTargetKey(target, windowUid)
                        if (!store.state.openStudies[studyUid].includes(targetKey)) {
                            store.state.openStudies[studyUid].push(targetKey)
                        }
                        Vue.$log.debug(`openStudy: studyUid=${studyUid} target=${target} windowUid=${windowUid} lockCount=${store.state.openStudies[studyUid].length}`)
                        resolve(true)

                        // Don't release lock until all component using it have closed it.
                        //
                        if ((closeStudyUid != '') && (closeStudyUid != studyUid)) {
                            closeStudy(closeStudyUid, target, windowUid)
                            .then(() => {
                                Vue.$log.debug("Closed previously selected study.")
                            })
                            .catch(err => {
                                Vue.$log.error("Error closing previously selected study: "+err.message)
                            })
                        }
                    }
                    else {
                        const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] " + response.result
                        reject(new Error(errorMsg))
                    }
                })   
                .catch(err => {
                    Vue.$log.error("Error updating lock setting for study: "+err.message)
                    const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] Unable to lock study."
                    reject(new Error(errorMsg))
                })
            }
            else {
                // This allows users without lock permission to access locked studies.
                //
                resolve(true)
            }
        }
        else {
            Vue.$log.warn(`No worklist entry found for studyUid=${studyUid} target=${target} windowUid=${windowUid}`)
            reject(new Error("Unable to find study."))
        }
    })
}

// Handles unlocking study if needed.
//
async function closeStudy(studyUid, target, windowUid='') {
    return new Promise((resolve, reject) => {
        const targetKey = getTargetKey(target, windowUid)
        if (store.state.openStudies[studyUid] != undefined) {
            store.state.openStudies[studyUid] = store.state.openStudies[studyUid].filter(item => {
                return (item != targetKey)
            })
            Vue.$log.debug(`closeStudy: studyUid=${studyUid} target=${target} windowUid=${windowUid} lockCount=${store.state.openStudies[studyUid].length}`)
            if (store.state.openStudies[studyUid].length == 0) {
                // Release lock for study.
                //
                var worklistEntry = store.getters.worklistEntryForStudy(studyUid)
                if (worklistEntry == null) {
                    // Create psuedo entry using management report list...
                    //
                    worklistEntry = store.getters.worklistEntryFromMgmtReport(studyUid)
                }
                if (worklistEntry != null) {
                    webServices.updateStudyLock(worklistEntry, 'unlock')
                    .then(response => {
                        if (response.result == 'OK') {
                            worklistEntry.lock = ''
                            worklistEntry.lufn = ''
                            let mgmtReportEntries = store.getters.mgmtReportEntriesForStudy(studyUid)
                            for (var i=0; i < mgmtReportEntries.length; i++) {
                                mgmtReportEntries[i]._l = response.studyLock
                            }
                            delete store.state.openStudies[studyUid]
                            store.commit('removeSecondaryWorklistEntry', studyUid)
                            resolve(true)
                        }
                        else {
                            const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] " + response.result
                            reject(new Error(errorMsg))
                        }
                    })   
                    .catch(err => {
                        Vue.$log.error("Error updating lock setting for study: "+err.message)
                        const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] Unable to unlock study."
                        reject(new Error(errorMsg))
                    })
                }
                else {
                    reject(new Error('Unable to unlock study, worklist entry not found.'))
                }
            }
            else {
                resolve(true)
            }
        }
        else {
            Vue.$log.debug(`closeStudy: not found studyUid=${studyUid} target=${target} windowUid=${windowUid}`)
            resolve(true)
        }
    })
}

async function removeAllLocks() {
    Vue.$log.debug('removeAllLocks: openStudies='+JSON.stringify(store.state.openStudies))
    let studyUids = Object.keys(store.state.openStudies)
    for (var k = 0; k < studyUids.length; k++) {
        let studyUid = studyUids[k]
        let targetKeys = store.state.openStudies[studyUid]
        for (let t = 0; t < targetKeys.length; t++) {
            let targetKey = targetKeys[t]
            try {
                await closeStudy(studyUid, targetKey)
                Vue.$log.debug(`close study successful for studyUid=[${studyUid}] targetKey=[${targetKey}]`)
            }
            catch(err) {
              Vue.$log.warn(`close study failed for studyUid=[${studyUid}] targetKey=[${targetKey}] err=${err.message}`)
            }
        }
    }
}

function openSecondaryReport(studyUid) {
    let reportWindowUid = uuid.v4()
    let payload = {
        'studyUid': studyUid,
        'windowUid': reportWindowUid
    }
    store.commit('changeSelectedStudyUids', payload)
    let box = store.state.reportWindowBox
    const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
    let reportWindowName = 'saincepacs_report_'+reportWindowUid
    let reportWindow = window.open('/#/report?uid='+encodeURIComponent(reportWindowUid), reportWindowName, windowOpts)
    store.commit('changeReportWindows', {
      'window': reportWindow,
      'windowUid': reportWindowUid
    })  
}

function openSecondaryViewer(route, studyUid) {
    let viewerWindowUid = uuid.v4()
    let viewerRoute = route + '?uid=' + encodeURIComponent(viewerWindowUid)
    let payload = {
        'studyUid': studyUid,
        'windowUid': viewerWindowUid
    }
    store.commit('changeSelectedStudyUids', payload)
    store.commit('changeViewerWindowsRoute', {
      'route': viewerRoute,
      'windowUid': viewerWindowUid
    })
    let box = store.state.viewerWindowBox
    const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
    let viewerWindowName = 'saincepacs_viewer_'+viewerWindowUid
    let viewerWindow = window.open(`/#/${viewerRoute}`, viewerWindowName, windowOpts)
    store.commit('changeViewerWindows', {
      'window': viewerWindow,
      'windowUid': viewerWindowUid
    })  
}

export default {
    TARGET_REPORT_SIDEPANEL,
    TARGET_REPORT_WINDOW,
    TARGET_VIEWER,

    closeStudy,
    getLockedStudyUid,
    openStudy,
    openSecondaryReport,
    openSecondaryViewer,
    removeAllLocks
}